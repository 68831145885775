<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          fab
          small
          title="Visualizar dados"
          icon
          :disabled="!item.data"
          v-bind="attrs"
          v-on="on"
          @click="dialog = true"
        >
          <v-icon>
            mdi-eye
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          Dados da requisição
        </v-card-title>
        <div>
          <textarea
            :value="item.data"
            cols="50"
            rows="25"
          />
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialog = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props: {
      item: {
        type: Object,
        required: true,
      },
    },

    data () {
      return {
        dialog: false,
      }
    },

    computed: {
      formattedJSON () {
        return JSON.stringify(this.item.data, null, 2)
      },
    },
  }
</script>
