<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="loading" />
        <div v-else>
          <crud-list
            v-model="dialogos"
            :headers="headers"
            :item-class="itemRowBackground"
            :sort-by="['data_dialogo', 'tipo']"
            :sort-desc="[true, false]"
            :slots="['item.data_dialogo', 'item.tipo', 'item.pergunta']"
            export-pdf-xlsx
          >
            <template slot="datas">
              <v-col
                cols="6"
              >
                <data-field
                  v-model="data_inicial"
                  :label="'Data Inicial'"
                  :data-max="data_final"
                  @set="handleDataFilter()"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <data-field
                  v-model="data_final"
                  :label="'Data Final'"
                  :data-min="data_inicial"
                  @set="handleDataFilter()"
                />
              </v-col>
            </template>
            <template v-slot:[`item.data_dialogo`]="{ item }">
              {{ moment(item.data_dialogo).format('DD/MM/YYYY HH:mm:ss') }}
            </template>

            <template v-slot:[`item.tipo`]="{ item }">
              <v-chip
                :color="colorStatus(item.tipo)"
                small
                class="mb-2 pl-4 pr-4 my-1"
              >
                {{ item.tipo }}
              </v-chip>
            </template>

            <template v-slot:[`item.pergunta`]="{ item }">
              <div class="cell-wrap">
                {{ item.pergunta }}
              </div>
            </template>

            <template v-slot:[`item.data`]="{ item }">
              <data-dialog
                :item="item"
              />
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import DialogosStore, { BOOTSTRAP } from '@/store/modules/dialogos'
  import { mapState, mapActions } from 'vuex'
  import { StatusLog } from '../../utils/constants'
  import DataDialog from './DataDialog'

  export default {
    name: 'Dialogos',
    components: {
      DataDialog,
    },

    data () {
      return {
        headers: [
          { align: 'center', text: 'Data', value: 'data_dialogo', sortable: true, groupable: false, floatingfilter: false, report: true },
          { align: 'left', text: 'Usuário', value: 'nomeUsuario', sortable: true, groupable: true, floatingfilter: true, report: true },
          { align: 'left', text: 'Unidade de Negócio', value: 'unidadeNegocio.nome_fantasia', sortable: true, groupable: true, floatingfilter: true, width: 'auto' },
          { align: 'left', text: 'Tópico', value: 'tipo', sortable: true, groupable: true, floatingfilter: true, report: true },
          { align: 'left', text: 'Pergunta', value: 'pergunta', sortable: true, groupable: true, floatingfilter: true, report: true },
        ],
        data_inicial: 'from',
        data_final: 'to',
      }
    },

    computed: {
      ...mapState('dialogos', ['dialogos', 'loading']),
    },

    created () {
      if (!this.$store.hasModule('dialogos')) { this.$store.registerModule('dialogos', DialogosStore) }

      let data_i = new Date()
      data_i.setMonth(data_i.getMonth() - 3)
      data_i = data_i.toISOString().slice(0, 10)
      const data_f = new Date().toISOString().slice(0, 10)
      this.data_inicial = data_i
      this.data_final = data_f

      this.BOOTSTRAP({ data_inicial: data_i, data_final: data_f })
    },

    methods: {
      ...mapActions('dialogos', [BOOTSTRAP]),

      itemRowBackground: function (item) {
        if (item.tipo === 'Não entendi') {
          return 'red lighten-5'
        } else {
          return 'green lighten-5'
        }
      },

      colorStatus (status) {
        if (status === 'Não entendi') {
          return 'error'
        } else {
          return 'success'
        }
      },

      handleDataFilter () {
        this.BOOTSTRAP({ data_inicial: this.data_inicial, data_final: this.data_final })
      },
    },
  }
</script>
<style>
.cell-wrap {
  white-space: normal !important;
  word-wrap: break-word;
  text-align: justify;
  width: 800px; /* Defina a largura desejada */
}
</style>
