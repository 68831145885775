import api from '@/api/api'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const LOAD_BATCH = 'LOAD_BATCH'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    dialogos: null,
    loading: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [BOOTSTRAP] ({ dispatch, commit, state }, { data_inicial, data_final } = { data_inicial: 'from', data_final: 'to' }) {
    commit(SET_STATE, { loading: true })
    await dispatch(LOAD_BATCH, { data_inicial, data_final })
    commit(SET_STATE, { loading: false })
  },
  async [LOAD_BATCH] ({ dispatch, commit }, { data_inicial, data_final } = { data_inicial: 'from', data_final: 'to' }) {
    await api.listEntidade('dialogos/' + data_inicial + '/' + data_final).then(
      response => {
        const dialogos = response.map(
          ({ ...rest }) => {
            const acc = {
              ...rest,
              nomeUsuario: rest.nome ? rest.nome : rest.razao_social,
            }
            return acc
          }, [],
        )
        commit(SET_STATE, { dialogos })
      },
    )
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
